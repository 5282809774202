<template>
	<div
		class="player-controls"
		:class="[
			`-${computedSize}`,
			`-timeline-${timelinePlacement}`,
			{ '-no-border': !showBorder },
			{ '-timeline-full-width': timelineFullWidth },
			{ '-controls-full-width': controlsFullWidth },
			{ '-is-mobile-web-app': isMobileWebApp },
			{ '-dark': darkTheme },
			{ 'is-scrubber-dragging': scrubberDragging },
		]"
	>
		<!-- Keep div -->
		<div class="player-controls__controls-top">
			<template v-if="isMobileWebApp">
				<AudioTracksButton
					ref="audioTracksButtonTop"
					:audio-tracks="audioTracks"
					:current-audio-track-id="currentAudioTrackId"
					:control-size="computedSize"
					:force-show="forceShowAudioTracks"
					:is-mobile-web-app="true"
					:is-touch-device="isTouchDevice"
					@set-audio-track="(value) => $emit('set-audio-track', value)"
					@opened="$emit('audio-tracks-opened')"
					@still-open="$emit('audio-tracks-still-open')"
					@close="$emit('audio-tracks-close')"
					@menu-toggle="handleAudioTracksMenuToggle"
				/>
				<SettingsButton
					ref="settingsButtonTop"
					:is-playback-speed-enabled="isPlaybackSpeedEnabled"
					:is-quality-settings-enabled="isQualitySettingsEnabled"
					:control-size="computedSize"
					:is-mobile-web-app="true"
					:is-touch-device="isTouchDevice"
					:quality-levels="qualityLevels"
					:current-quality-id="currentQualityLevelId"
					:speed-options="speeds"
					:current-speed="currentSpeed"
					@set-quality="handleSetQuality"
					@set-speed="handleSetSpeed"
					@opened="$emit('settings-opened')"
					@still-open="$emit('settings-still-open')"
					@close="$emit('settings-close')"
					@menu-toggle="handleSettingsMenuToggle"
				/>
			</template>
		</div>
		<div class="player-controls__controls-bottom">
			<div
				v-if="showTimeline"
				class="player-controls__timeline"
			>
				<MinimalTimeline
					:elapsed="position"
					:duration="duration"
					:hide-scrubber="!showScrubber"
					:is-playing="isPlaying"
					:timeline-placement="timelinePlacement"
					:is-seekable="isSeekable"
					@cursor-drag-start="$emit('cursor-drag-start')"
					@cursor-drag-end="$emit('cursor-drag-end')"
					@play="$emit('play')"
					@pause="$emit('pause')"
					@seek="(value) => $emit('seek', value)"
				/>
			</div>
			<div
				class="player-controls__controls-w"
				@click.stop="() => {}"
			>
				<div class="player-controls__bottom-left">
					<PlayToggle
						v-if="showPlayToggle"
						:is-playing="isPlaying"
						:pause-enabled="pauseEnabled"
						:stop-enabled="stopEnabled"
						:size="computedSize"
						:dark-theme="darkTheme"
						@play="$emit('play')"
						@pause="$emit('pause')"
						@stop="$emit('stop')"
					/>
					<!-- reload -->
					<button
						v-if="showReload"
						v-tooltip="'Reload'"
						class="player-controls__button"
						@click.stop="$emit('reload')"
					>
						<Icon
							icon-name="reload"
							:size="computedSize"
						/>
					</button>
					<VolumeControl
						:is-muted="isMuted"
						:volume-level="volumeLevel"
						:size="computedSize"
						:show-volume-selector="showVolumeSelector"
						:dark-theme="darkTheme"
						@mute-toggle="$emit('mute-toggle')"
						@set-mute="(value) => $emit('set-mute', value)"
						@set-volume="(value) => $emit('set-volume', value)"
					/>
					<!-- replay -->
					<button
						v-if="showReplay"
						v-tooltip="tooltips.replay"
						class="player-controls__button"
						@click.stop="$emit('replay')"
					>
						<Icon
							icon-name="replay"
							:size="computedSize"
						/>
					</button>
					<TimeDisplay
						v-if="showTimeDisplay"
						:time="timeDisplay"
						:show-duration="showDuration"
						:size="computedSize"
						:dark-theme="darkTheme"
					/>
					<!-- live badge -->
					<BadgeLive
						v-if="showLiveBadge"
						:modifier="computedSize === 'sm' || (computedSize === 'sm' && isEmbeddedMode) || isMobileWebApp ? 'small' : 'medium'"
						:hide-text="computedSize === 'sm' || isMobileWebApp"
						:not-live="isLookingBackInLiveEvent"
						:clickable="isLookingBackInLiveEvent"
						@click="$emit('back-to-live')"
					/>
				</div>
				<div
					v-if="showTagNavigation"
					class="player-controls__bottom-center"
				>
					<button class="player-controls__button">
						<Icon
							icon-name="left-arrow"
							:size="computedSize"
							@click.stop="$emit('prev-clip')"
						/>
					</button>
					<span>{{ currentTagNumber }} / {{ tagsCount }}</span>
					<button class="player-controls__button">
						<Icon
							icon-name="right-arrow"
							:size="computedSize"
							@click.stop="$emit('next-clip')"
						/>
					</button>
				</div>
				<div class="player-controls__bottom-right">
					<slot name="right-controls-start" />
					<LikeButton
						v-if="showLikeButton"
						class="clip-like"
						:likes="likes"
						:is-liked="isLiked"
						:size="computedSize"
						modifier="horizontal"
						@like="$emit('like')"
					/>
					<span v-if="showQualityString && qualityLevels.length > 0">
						{{ qualityLevels[currentQualityLevelId].label || '' }}
					</span>
					<!-- logo -->
					<a
						v-if="showClientLogo && playerLogoUrl"
						:href="clientLogoTargetURL"
						target="_blank"
					>
						<img
							class="player-controls__logo"
							:src="playerLogoUrl"
							alt="logo"
						>
					</a>
					<button
						v-if="showShareButton"
						v-tooltip="tooltips.share"
						class="player-controls__button"
						@click.stop="$emit('share')"
					>
						<Icon
							icon-name="network-communication"
							:size="computedSize"
						/>
					</button>

					<template v-if="!isMobileWebApp">
						<!-- audio tracks -->
						<AudioTracksButton
							ref="audioTracksButtonBottom"
							:audio-tracks="audioTracks"
							:current-audio-track-id="currentAudioTrackId"
							:control-size="computedSize"
							:force-show="forceShowAudioTracks"
							:is-mobile-web-app="false"
							:is-touch-device="isTouchDevice"
							@set-audio-track="(value) => $emit('set-audio-track', value)"
							@opened="$emit('audio-tracks-opened')"
							@still-open="$emit('audio-tracks-still-open')"
							@close="$emit('audio-tracks-close')"
							@menu-toggle="handleAudioTracksMenuToggle"
						/>

						<!-- settings -->
						<SettingsButton
							ref="settingsButtonBottom"
							:is-playback-speed-enabled="isPlaybackSpeedEnabled"
							:is-quality-settings-enabled="isQualitySettingsEnabled"
							:control-size="computedSize"
							:is-mobile-web-app="false"
							:is-touch-device="isTouchDevice"
							:quality-levels="qualityLevels"
							:current-quality-id="currentQualityLevelId"
							:speed-options="speeds"
							:current-speed="currentSpeed"
							@set-quality="handleSetQuality"
							@set-speed="handleSetSpeed"
							@opened="$emit('settings-opened')"
							@still-open="$emit('settings-still-open')"
							@close="$emit('settings-close')"
							@menu-toggle="handleSettingsMenuToggle"
						/>
					</template>

					<!-- airplay -->
					<button
						v-if="airPlayConfig.available"
						v-tooltip="tooltips.airPlay"
						class="player-controls__button"
						@click="handleAirPlayRequest"
					>
						<Icon
							icon-name="airplay"
							:size="computedSize"
						/>
					</button>

					<!-- chromecast -->
					<button
						v-if="isChromecastAvailable"
						class="player-controls__button"
						@click.stop="$emit('chromecast-click')"
					>
						<Icon
							icon-name="device-connection"
							:size="computedSize"
						/>
					</button>

					<!-- cinema mode -->
					<button
						v-if="showCinemaMode"
						v-tooltip="isCinemaMode ? tooltips.defaultView : tooltips.cinemaMode"
						class="player-controls__button"
						@click.stop="$emit('cinema-click')"
					>
						<Icon
							:icon-name="isCinemaMode ? 'end-cinema-mode' : 'cinema-mode'"
							:size="computedSize"
						/>
					</button>

					<!-- fullscreen -->
					<button
						v-if="showFullscreen"
						v-tooltip="tooltips.fullscreen"
						class="player-controls__button"
						@click.stop="$emit('fullscreen-click')"
					>
						<Icon
							:icon-name="isFullscreen ? 'pinch' : 'fullscreen-double'"
							:size="computedSize"
						/>
					</button>
					<slot name="right-controls-end" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MinimalTimeline from '@shared/components/PlayerControls/MinimalTimeline.vue';
import PlayToggle from '@shared/components/PlayerControls/PlayToggle.vue';
import VolumeControl from '@shared/components/PlayerControls/VolumeControl.vue';
import TimeDisplay from '@shared/components/PlayerControls/TimeDisplay.vue';
import BadgeLive from '@shared/components/BadgeLive';
import LikeButton from '@shared/components/LikeButton';
import Icon from '@shared/components/Icon.vue';
import { formatTimestampAsMinutesSeconds } from '@shared/utils/timeUtils';
import { VTooltip } from 'v-tooltip';
import AudioTracksButton from './AudioTracksButton.vue';
import SettingsButton from './SettingsButton.vue';

export default {
	name:       'PlayerControls',
	components: {
		MinimalTimeline,
		PlayToggle,
		Icon,
		VolumeControl,
		LikeButton,
		TimeDisplay,
		BadgeLive,
		AudioTracksButton,
		SettingsButton,
	},
	directives: {
		tooltip: VTooltip,
	},
	props: {
		/// /////////////
		// UI config
		/// /////////////
		size: {
			type:      String,
			default:   'lg',
			validator: (value) => ['sm', 'md', 'lg'].includes(value),
		},
		showPlayToggle: {
			type:    Boolean,
			default: true,
		},
		showTagNavigation: {
			type:    Boolean,
			default: false,
		},
		showReload: {
			type:    Boolean,
			default: false,
		},
		showReplay: {
			type:    Boolean,
			default: false,
		},
		showTimeDisplay: {
			type:    Boolean,
			default: true,
		},
		showLikeButton: {
			type:    Boolean,
			default: false,
		},
		showClientLogo: {
			type:    Boolean,
			default: false,
		},
		clientLogoTargetURL: {
			type:    String,
			default: '',
		},
		showShareButton: {
			type:    Boolean,
			default: true,
		},
		showDuration: {
			type:    Boolean,
			default: true,
		},
		showFullscreen: {
			type:    Boolean,
			default: true,
		},
		showCinemaMode: {
			type:    Boolean,
			default: true,
		},
		showQualityString: {
			type:    Boolean,
			default: false,
		},
		showTimeline: {
			type:    Boolean,
			default: true,
		},
		stopEnabled: {
			type:    Boolean,
			default: false,
		},
		pauseEnabled: {
			type:    Boolean,
			default: true,
		},
		showVolumeSelector: {
			type:    Boolean,
			default: true,
		},
		showBorder: {
			type:    Boolean,
			default: true,
		},
		showScrubber: {
			type:    Boolean,
			default: true,
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		timelinePlacement: {
			type:      String,
			default:   'top',
			validator: (value) => ['top', 'bottom'].includes(value),
		},
		timelineFullWidth: {
			type:    Boolean,
			default: false,
		},
		controlsFullWidth: {
			type:    Boolean,
			default: false,
		},
		forceShowAudioTracks: {
			type:    Boolean,
			default: false,
		},
		showLiveBadge: {
			type:    Boolean,
			default: false,
		},
		isPlaybackSpeedEnabled: {
			type:    Boolean,
			default: false,
		},
		isQualitySettingsEnabled: {
			type:    Boolean,
			default: false,
		},
		isMobileWebApp: {
			type:    Boolean,
			default: false,
		},
		isEmbeddedMode: {
			type:    Boolean,
			default: false,
		},
		/// /////////////
		// Player config
		/// /////////////
		storedQualityLevelId: {
			type:    Number,
			default: 0,
		},
		playerSpeedOptions: {
			type:    Array,
			default: () => [0.25, 0.5, 1, 1.5, 2],
		},
		/// /////////////
		// Playback state
		/// /////////////
		isPlaying: {
			type:    Boolean,
			default: false,
		},
		duration: {
			type:    Number,
			default: 0,
		},
		position: {
			type:    Number,
			default: 0,
		},
		isLive: {
			type:    Boolean,
			default: false,
		},
		isLookingBackInLiveEvent: {
			type:    Boolean,
			default: false,
		},
		qualityLevels: {
			type:    Array,
			default: () => [],
		},
		volumeLevel: {
			type:    Number,
			default: 50,
		},
		isMuted: {
			type:    Boolean,
			default: false,
		},
		currentSpeed: {
			type:    Number,
			default: 1,
		},
		audioTracks: {
			type:    Array,
			default: () => [],
		},
		currentAudioTrackId: {
			type:    Number,
			default: 0,
		},
		scrubberDragging: {
			type:    Boolean,
			default: false,
		},
		isSeekable: {
			type:    Boolean,
			default: true,
		},
		/// /////////////
		// Player state
		/// /////////////
		isFullscreen: {
			type:    Boolean,
			default: false,
		},
		isCinemaMode: {
			type:    Boolean,
			default: false,
		},
		/// /////////////
		// Devide/Browser capabilities
		/// /////////////
		isTouchDevice: {
			type:    Boolean,
			default: false,
		},
		airPlayConfig: {
			type:    Object,
			default: () => ({}),
		},
		isChromecastAvailable: {
			type:    Boolean,
			default: false,
		},
		/// /////////////
		// Video specific
		/// /////////////
		currentTagNumber: {
			type:    Number,
			default: 0,
		},
		tagsCount: {
			type:    Number,
			default: 0,
		},
		likes: {
			type:    Number,
			default: 0,
		},
		isLiked: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			showAudioTracksMenu:   false,
			showSettingsMenu:      false,
			currentQualityLevelId: 0,
		};
	},
	computed: {
		computedSize() {
			if (this.isTouchDevice && !this.isEmbeddedMode) {
				return 'lg';
			}
			return this.size;
		},
		timeDisplay() {
			const elapsed = Math.max(0, Number.isFinite(this.position) ? this.position : 0);
			const duration = Math.max(0, Number.isFinite(this.duration) ? this.duration : 0);
			return {
				elapsed:  formatTimestampAsMinutesSeconds(elapsed),
				duration: formatTimestampAsMinutesSeconds(duration),
			};
		},
		speeds() {
			return this.playerSpeedOptions.map((value) => {
				const id = `player-speed-${value}`;
				const label = `${value}x`;
				const disabled = this.isLive && !this.isLookingBackInLiveEvent && value !== 1;
				return {
					id,
					label,
					value,
					disabled,
				};
			});
		},
		audioTracksMenuProps() {
			return {
				variant:             this.isMobileWebApp ? 'mobile' : 'default',
				audioTrackOptions:   this.audioTracks,
				currentAudioTrackId: this.currentAudioTrackId,
			};
		},
		settingsMenuProps() {
			const currentSpeed = this.speeds.find((speed) => speed.value === this.currentSpeed);

			return {
				variant:                  this.isMobileWebApp ? 'mobile' : 'default',
				qualityOptions:           this.qualityLevels,
				currentQualityId:         this.currentQualityLevelId,
				speedOptions:             this.speeds,
				currentSpeedId:           currentSpeed?.id,
				isPlaybackSpeedEnabled:   this.isPlaybackSpeedEnabled,
				isQualitySettingsEnabled: this.isQualitySettingsEnabled,
			};
		},
		tooltips() {
			if (this.isTouchDevice || this.showSettingsMenu || this.showAudioTracksMenu) return {};

			const items = {
				fullscreen:    `view_mode.${this.isFullscreen ? 'exit' : 'enter'}_full_screen`,
				playbackSpeed: 'view_mode.playback_peed',
				share:         'view_mode.share',
				settings:      'commons.settings',
				replay:        'view_mode.replay',
				audioTracks:   'view_mode.language',
				cinemaMode:    'commons.cinema_mode',
				defaultView:   'commons.default_view',
				airPlay:       'view_mode.air_play',
			};

			return Object.entries(items).reduce((acc, [key, content]) => {
				acc[key] = {
					content:   this.$I18n.trans(content),
					classes:   `-size-${this.computedSize} tooltip`,
					container: key === 'fullscreen' ? this.$root.$el : false,
					trigger:   'hover',
				};
				return acc;
			}, {});
		},
		playerLogoUrl() {
			let logoUrl = null;
			if (window.isAsport) {
				logoUrl = '/images/player-logo/asport-lg.svg';
			} else if (window.isSportPass) {
				logoUrl = '/images/player-logo/sportpass-lg.png';
			}
			return this.computedSize === 'sm' || (this.size === 'sm' && this.isTouchDevice) ? logoUrl.replace('-lg', '-sm') : logoUrl;
		},
	},
	watch: {
		isLookingBackInLiveEvent(value) {
			// when reaching the live point, set the playback speed to 1
			if (!value) {
				this.$emit('set-speed', 1);
			}
		},
		storedQualityLevelId(value) {
			if (Number.isFinite(value)) this.currentQualityLevelId = value;
		},
	},
	mounted() {
		if (Number.isFinite(this.storedQualityLevelId)) this.currentQualityLevelId = this.storedQualityLevelId;
		document.addEventListener('click', this.handleClickOutside, { capture: true });
		// Outside the iframe
		try {
			if (typeof parent !== 'undefined' && document !== parent?.document) {
				parent.document.addEventListener('click', this.handleClickOutside, { capture: true });
			}
		} catch {}
	},
	beforeDestroy() {
		document.removeEventListener('click', this.handleClickOutside);
		// Outside the iframe
		try {
			if (typeof parent !== 'undefined' && document !== parent?.document) {
				parent.document.removeEventListener('click', this.handleClickOutside);
			}
		} catch {}
	},
	methods: {
		handleClickOutside(e) {
			// Get the active audio tracks button (either top or bottom)
			const audioTracksButton = this.isMobileWebApp
				? this.$refs.audioTracksButtonTop
				: this.$refs.audioTracksButtonBottom;

			// Get the active settings button (either top or bottom)
			const settingsButton = this.isMobileWebApp
				? this.$refs.settingsButtonTop
				: this.$refs.settingsButtonBottom;

			// Check if click is outside the audio tracks button
			if (audioTracksButton && !audioTracksButton.$refs.audioTracksMenuButton?.contains(e.target)) {
				if (this.showAudioTracksMenu) {
					this.showAudioTracksMenu = false;
					audioTracksButton.closeMenu();
					e.stopPropagation();
				}
			}

			// Check if click is outside the settings button
			if (settingsButton && !settingsButton.$refs.settingsMenuButton?.contains(e.target)) {
				if (this.showSettingsMenu) {
					this.showSettingsMenu = false;
					settingsButton.closeMenu();
					e.stopPropagation();
				}
			}
		},
		handleSetSpeed(value) {
			this.$emit('set-speed', value);
		},
		handleSetQuality(id) {
			this.currentQualityLevelId = id;
			this.$emit('set-quality', id);
		},
		handleAirPlayRequest() {
			if (this.airPlayConfig.videoEl) {
				this.airPlayConfig.videoEl.webkitShowPlaybackTargetPicker();
			}
		},
		handleAudioTracksMenuToggle(value) {
			this.showAudioTracksMenu = value;
			this.$emit('settings-pop-out-toggle', value);
		},
		handleSettingsMenuToggle(value) {
			this.showSettingsMenu = value;
			this.$emit('settings-pop-out-toggle', value);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.player-controls {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	border-top: 1px solid rgba(var(--color-rgb-grey-500), 0.4);

	&.-no-border {
		border-top: none;
	}

	// override horizontal padding for full width
	&.-controls-full-width {
		--controls-h-padding: 0;
	}

	&.-is-mobile-web-app {
		--controls-h-padding: var(--controls-h-padding-mobile-web-app);
	}
}

.player-controls__timeline {
	width: 100%;
	padding-left: 2.5%;
	padding-right: 2.5%;

	.player-controls.-timeline-full-width & {
		padding-left: 0;
		padding-right: 0;
	}
}

// only used for mobile web app
// keep displyed for flex layout
.player-controls__controls-top {
	position: relative;
	display: flex;
	justify-content: flex-end;
	padding: var(--controls-top-v-padding) var(--controls-h-padding);
	gap: $sp5;
	transition: opacity $player-overlay-animation;
	z-index: 2; // make sure they are above the bottom controls (important for mobile web app)

	&:empty {
		height: 0;
		padding: 0;
	}

	.player-controls.is-scrubber-dragging & {
		opacity: 0;
	}
}

.player-controls__controls-bottom {
	display: flex;
	flex-direction: column;

	.player-controls.-timeline-bottom & {
		flex-direction: column-reverse;
	}
}

.player-controls__controls-w {
	display: flex;
	justify-content: space-between;
	padding: $sp3-5 var(--controls-h-padding);

	.player-controls.-md & {
		padding: $sp4 var(--controls-h-padding);
	}

	.player-controls.-lg & {
		padding: $sp4 var(--controls-h-padding);
	}
}

.player-controls__bottom-left,
.player-controls__bottom-center,
.player-controls__bottom-right {
	display: flex;
	align-items: center;
	gap: $sp3-5;

	.player-controls.-md & {
		gap: $sp4;
	}

	.player-controls.-lg & {
		gap: $sp5;

		.is-mobile-web-app & {
			gap: $sp4;
		}
	}
}

.player-controls__bottom-right {
	transition: opacity $player-overlay-animation;

	.player-controls.is-scrubber-dragging & {
		opacity: 0;
	}
}

.player-controls__logo {
	height: 24px;
	display: block;

	.player-controls.-sm & {
		height: 16px;
	}
}

.player-controls__button {
	position: relative;
	display: flex;
	color: var(--color-white);
	line-height: 1;
	cursor: pointer;

	.player-controls.-dark & {
		color: var(--color-black);
	}
}

.player-controls__menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s, opacity 0.2s ease-in-out;
	text-align: center;
	bottom: 32px;
	right: -16px;

	&.is-visible {
		visibility: visible;
		opacity: 1;
	}
}
</style>
